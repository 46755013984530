import React, { Component } from 'react'
import { Page, Wrapper, Logo } from '../sharedComponents/styles'
import { Card, Input, Form, Icon, Button, message } from 'antd'
import { postRequestTo } from '../utils/requestComponents'
import { Typography } from 'antd'
import styled from 'styled-components'
import PasswordSuccess from './PasswordSuccess'

export const InvalidLink = styled.img`
	max-width: 50vw;
	height: ${(props) => props.height};
	width: ${(props) => props.height};
	content: url('/images/password-link-expired.svg');
	margin: 2rem 0;
`

const { Title, Text } = Typography
const status = {
	SUCCESS: 'success',
	ERROR: 'error', 
	LOADING: 'loading', 
}
const PasswordCard = styled(Card)`
	box-shadow: 0px 0px 25px rgba(69, 107, 132, 0.15);
	border-radius: 4px;
	min-width: 300px;
	@media only screen and (min-device-width: 375px) {
		min-width: 330px;
	}

	@media only screen and (min-device-width: 425px) {
		min-width: 360px;
	}
`

class CreatePassword extends Component {
	state = {
		confirmDirty: false,
		verified: status.LOADING,
		loading: false,
		changed: false,
	}

	componentDidMount() {
		const params = new URLSearchParams(this.props.history.location.search)
		const { token, expiresOn, redirectUrl } = {
			token: params.get('token'),
			expiresOn: params.get('expiresOn'),
			redirectUrl: params.get('redirectUrl'),
		}
		if (!token || !expiresOn || Date.now() > expiresOn)
			return this.setState({ verified: status.ERROR, message: 'Link is invalid or expired', errorMessage: 'Please resend the email or contact support' })
		this.setState({ verified: status.SUCCESS, token, redirectUrl })
	}

	handleSubmit = (e) => {
		e.preventDefault()
		this.props.form.validateFields(async (err, values) => {
			if (!!err) return message.error('Invalid field values')
			const data = {
				newPassword: values.password1,
				token: values.token,
			}

			try {
				this.setState({ loading: true })
				const response = await postRequestTo(`/authentication/v1/consumer/resetPassword`, data, 'POST', true)
				// if (!!response) this.props.history.replace('/passwordChanged')
				if (!!response) this.setState({ changed: true })
				else throw new Error()
			} catch (e) {
				message.error('Something went wrong, please try again')
			} finally {
				this.setState({ loading: false })
			}
		})
	}

	compareToFirstPassword = (rule, value, callback) => {
		const { form } = this.props
		if (value && value !== form.getFieldValue('password1')) {
			callback('Two passwords that you enter is inconsistent!')
		} else {
			callback()
		}
	}

	validateToNextPassword = (rule, value, callback) => {
		const { form } = this.props
		if (value && this.state.confirmDirty) {
			form.validateFields(['password2'], { force: true })
		}
		callback()
	}

	handleConfirmBlur = (e) => {
		const { value } = e.target
		this.setState({ confirmDirty: this.state.confirmDirty || !!value })
	}

	render() {
		const { getFieldDecorator } = this.props.form
		const { verified, token, loading, message, errorMessage, changed, redirectUrl } = this.state
		return (
			<Page>
				{verified === status.SUCCESS && !changed && (
					<Wrapper style={{ padding: 0, margin: 0 }}>
						<Logo style={{ marginBottom: '2rem' }} />
						<PasswordCard title='Create New Password' headStyle={{ textAlign: 'center', color: '#000000a6', fontSize: 15 }}>
							<Form onSubmit={this.handleSubmit} className='login-form'>
								<Form.Item hasFeedback help='The password must be 6-20 in length.'>
									{getFieldDecorator('password1', {
										rules: [
											{ required: true, message: 'Please input new password!' },
											{ min: 6, message: 'Password must be 6 or more charachters' },
											{ max: 20, message: 'Password should be 20 or less charachters' },
											{ validator: this.validateToNextPassword },
										],
									})(<Input.Password prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder='New Password' />)}
								</Form.Item>
								<Form.Item hasFeedback>
									{getFieldDecorator('password2', {
										rules: [{ required: true, message: 'Please input your Password!' }, { validator: this.compareToFirstPassword }],
									})(
										<Input.Password
											prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />}
											placeholder='Confirm Password'
											onBlur={this.handleConfirmBlur}
										/>,
									)}
								</Form.Item>
								{getFieldDecorator('token', { initialValue: token })}
								<Button loading={loading} style={{ width: '100%' }} type='primary' htmlType='submit'>
									Create Password
								</Button>
							</Form>
						</PasswordCard>
					</Wrapper>
				)}
				{verified !== status.SUCCESS && !changed && (
					<Wrapper className='card'>
						<Logo />
						<InvalidLink />
						<Title level={4} style={{ color: '#262626' }}>
							{message}
						</Title>
						{!!errorMessage && <Text>{errorMessage}</Text>}
					</Wrapper>
				)}
				{!!changed && (
					<PasswordSuccess
						type='set'
						message={
							<a href={'https://' + redirectUrl}>
								<Button type='primary'>Click here to login to the app</Button>
							</a>
						}
					/>
				)}
			</Page>
		)
	}
}

export default Form.create()(CreatePassword)
