import React, { Component } from 'react'
import { ThemeProvider } from 'styled-components'

// White labelling
import theme from './sharedComponents/TenantTheme'
import { Page } from './sharedComponents/styles'
import Routers from './pages'

class App extends Component {
	render() {
		return (
			<Page>
				<ThemeProvider theme={theme}>
					<Routers />
				</ThemeProvider>
			</Page> 
		)
	}
}

export default App
